import style from '../pages/style/index.module.scss'

// leaf
import Yellow01 from '../images/plants/yellow-leaf-01.svg';
import Blue04 from '../images/plants/blue-leaf-04.svg';
import Green03 from '../images/plants/green-leaf-03.svg';
import Red01 from '../images/plants/red-leaf-01.svg';

import Blue02 from '../images/plants/blue-leaf-02.svg';
import Green02 from '../images/plants/green-leaf-02.svg';
import Green04 from '../images/plants/green-leaf-04.svg';
import Green05 from '../images/plants/green-leaf-05.svg';
import Yellow03 from '../images/plants/yellow-leaf-03.svg';

import Yellow02 from '../images/plants/yellow-leaf-02.svg';
import White01 from '../images/plants/white-leaf-01.svg';
import Blue03 from '../images/plants/blue-leaf-03.svg';
import Blue01 from '../images/plants/blue-leaf-01.svg';
import Green01 from '../images/plants/green-leaf-01.svg';

import Green06 from '../images/plants/green-leaf-06.svg';
import Yellow05 from '../images/plants/yellow-leaf-05.svg';
import Blue06 from '../images/plants/blue-leaf-06.svg';
import Blue05 from '../images/plants/blue-leaf-05.svg';
import Yellow04 from '../images/plants/yellow-leaf-04.svg';

export const plants = [{
  id: style.Yellow01,
  aos: 'yellow01',
  alt: 'yellow algae',
  src: Yellow01,
},{
  id: style.Blue04,
  aos: 'blue04',
  alt: 'blue algae',
  src: Blue04,
},{
  id: style.Red01,
  aos: 'red01',
  alt: 'red algae',
  src: Red01,
},{
  id: style.Green03,
  aos: 'green03',
  alt: 'yellow algae',
  src: Green03,
}];

export const paralaxPlants = [{
  id: style.Blue02,
  src: Blue02,
  alt: 'blue algae',
  data: {
    'data-start': 'transform: rotate(-45deg)',
    'data-end': 'transform: rotate(90deg)',
  },
},{
  id: style.Green02,
  src: Green02,
  alt: 'green algae',
  data: {
    'data-start': 'transform: rotate(15deg)',
    'data-end': 'transform: rotate(-15deg)',
  },
},{
  id: style.Green04,
  src: Green04,
  alt: 'green algae',
  data: {
    'data-start': 'transform: rotate(45deg)',
    'data-end': 'transform: rotate(-90deg)',
  },
},{
  id: style.Green05,
  src: Green05,
  alt: 'green algae',
  data: {
    'data-start': 'transform: rotate(45deg)',
    'data-end': 'transform: rotate(-90deg)',
  },
},{
  id: style.Yellow03,
  src: Yellow03,
  alt: 'yellow algae',
  data: {
    'data-start': 'transform: rotate(15deg)',
    'data-end': 'transform: rotate(-45deg)',
  },
},];

export const paralaxPlants02 = [{
  id: style.Yellow02,
  src: Yellow02,
  alt: 'Yellow algae',
  data: {
    'data-start': 'transform: rotate(-35deg)',
    'data-end': 'transform: rotate(15deg)',
  },
},{
  id: style.Green01,
  src: Green01,
  alt: 'Green algae',
  data: {
    'data-start': 'transform: rotate(35deg)',
    'data-end': 'transform: rotate(-15deg)',
  },
},{
  id: style.Blue01,
  src: Blue01,
  alt: 'Blue algae',
  data: {
    'data-start': 'transform: rotate(5deg)',
    'data-end': 'transform: rotate(-35deg)',
  },
},{
  id: style.White01,
  src: White01,
  alt: 'White algae',
  data: {
    'data-start': 'transform: rotate(-45deg)',
    'data-end': 'transform: rotate(5deg)',
  },
},];

export const paralaxPlants04 = [{
  id: style.Blue03,
  src: Blue03,
  alt: 'Blue algae',
  data: {
    'data-start': 'transform: rotate(-15deg)',
    'data-end': 'transform: rotate(15deg)',
  },
},];

export const paralaxPlants03 = [{
  id: style.Yellow04,
  src: Yellow04,
  alt: 'Yellow algae',
  data: {
    'data-start': 'transform: rotate(-15deg)',
    'data-end': 'transform: rotate(0deg)',
  },
},{
  id: style.Green06,
  src: Green06,
  alt: 'Green algae',
  data: {
    'data-start': 'transform: rotate(45deg)',
    'data-end': 'transform: rotate(0deg)',
  },
},{
  id: style.Blue06,
  src: Blue06,
  alt: 'Blue algae',
  data: {
    'data-start': 'transform: rotate(-135deg)',
    'data-end': 'transform: rotate(15deg)',
  },
},{
  id: style.Blue05,
  src: Blue05,
  alt: 'Blue algae',
  data: {
    'data-start': 'transform: rotate(15deg)',
    'data-end': 'transform: rotate(-15deg)',
  },
},{
  id: style.Yellow05,
  src: Yellow05,
  alt: 'Yellow algae',
  data: {
    'data-start': 'transform: rotate(-45deg)',
    'data-end': 'transform: rotate(0deg)',
  },
},]

