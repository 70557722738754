import { Link } from "gatsby"
import React from "react"

export default function Cta({className, href, children, extern}) {
	if ( extern ) {
		return (
			<a
				rel="noopener noreferrer"
				target = '_blank'
				className = { className }
				href = { href }>{ children }</a>
		)
	}
	return (
		<Link
			rel="noopener noreferrer"
			target = { extern ? '_blank' : null }
			className = { className }
			to = { href }>{ children }</Link>
	)
}

Cta.defaultProps = {
	extern: false,
}